// App.js
import React from 'react';
import './App.css';
import Header from './components/Header';
import Services from './components/Services';
import Doctors from './components/Doctors.jsx';
import Features from './components/Features';
import Footer from './components/Footer';

function App() {
  return (
    <div className="app">
      <Header />
      <Services />
      <Doctors />
      <Features />
      <Footer />
    </div>
  );
}

export default App;