import React from 'react';
import '../styles/Services.css';

const XRayLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="service-icon">
        {/* X-Ray Machine Design */}
        <rect x="35" y="20" width="30" height="40" fill="#4169E1" rx="2" />
        <rect x="45" y="60" width="10" height="20" fill="#4169E1" />
        <rect x="25" y="80" width="50" height="5" fill="#4169E1" />
        <circle cx="50" cy="35" r="8" fill="white" />
        <path d="M42 35 L58 35 M50 27 L50 43" stroke="#4169E1" strokeWidth="2" />
    </svg>
);

const UltrasoundLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="service-icon">
        {/* Ultrasound Waves Design */}
        <rect x="20" y="40" width="30" height="20" fill="#4169E1" rx="2" />
        <path d="M60 30 Q 70 50, 60 70" fill="none" stroke="#4169E1" strokeWidth="4" />
        <path d="M65 25 Q 75 50, 65 75" fill="none" stroke="#4169E1" strokeWidth="4" />
        <path d="M70 20 Q 80 50, 70 80" fill="none" stroke="#4169E1" strokeWidth="4" />
    </svg>
);

const DopplerLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="service-icon">
        {/* Doppler Waves Design */}
        <circle cx="50" cy="50" r="25" fill="none" stroke="#4169E1" strokeWidth="3" />
        <path d="M25 50 Q 50 20, 75 50" fill="none" stroke="#4169E1" strokeWidth="3" />
        <path d="M25 50 Q 50 80, 75 50" fill="none" stroke="#4169E1" strokeWidth="3" />
        <circle cx="50" cy="50" r="5" fill="#4169E1" />
    </svg>
);

function Services() {
    const services = [
        {
            title: "500 MA Digital X-Ray",
            description: "State-of-the-art digital X-ray technology for precise imaging",
            Logo: XRayLogo
        },
        {
            title: "Ultrasound",
            description: "Advanced ultrasound equipment for detailed diagnostics",
            Logo: UltrasoundLogo
        },
        {
            title: "Colour Doppler",
            description: "High-precision color Doppler for vascular imaging",
            Logo: DopplerLogo
        }
    ];

    return (
        <section className="services">
            <div className="container">
                <h2>Our Advanced Equipment</h2>
                <div className="services-grid">
                    {services.map((service, index) => (
                        <div key={index} className="service-card">
                            <service.Logo />
                            <h3>{service.title}</h3>
                            <p>{service.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Services;