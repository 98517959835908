import React from 'react';
import '../styles/Features.css';

function Features() {
    const features = [
        {
            title: "40+ Years of Experience",
            description: "Serving since 1982 with dedication and expertise"
        },
        {
            title: "Fully Computerised Lab",
            description: "Modern equipment for accurate results"
        },
        {
            title: "Expert Team",
            description: "Qualified doctors and technicians"
        }
    ];

    return (
        <section className="features">
            <div className="container">
                <h2>Why Choose Us</h2>
                <div className="features-grid">
                    {features.map((feature, index) => (
                        <div key={index} className="feature">
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Features;