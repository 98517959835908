import React from 'react';
import '../styles/Footer.css';

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-content">
                    <div className="footer-section">
                        <h3>Contact Us</h3>
                        <div className="contact-details">
                            <p>
                                <i className="icon phone-icon"></i>
                                011-79660783, 9899908545
                            </p>
                            <p>
                                <i className="icon location-icon"></i>
                                IA-16A, Phase 1, Ashok Vihar-52
                            </p>
                        </div>
                    </div>
                    <div className="footer-section">
                        <h3>Working Hours</h3>
                        <p>
                            <i className="icon clock-icon"></i>
                            Open 7 days a week
                        </p>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>© {currentYear} Accurate Imaging & Path Labs. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;