import React from 'react';
// import Logo from './logo';
import '../styles/Header.css';
import certImage from '../assets/cert_image.jpeg';

const Logo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" className="header-logo">
        {/* Base Circle */}
        <circle cx="60" cy="60" r="58" fill="#FFFFFF" stroke="#1E3A8A" stroke-width="4" />

        {/* Microscope Base Design */}
        <g transform="translate(30, 25)">
            {/* Eyepiece */}
            <rect x="25" y="0" width="10" height="15" rx="2" fill="#1E3A8A" />

            {/* Head */}
            <path d="M20 15 L40 15 L42 25 L18 25 Z" fill="#1E3A8A" />

            {/* Arm */}
            <rect x="28" y="25" width="4" height="25" fill="#1E3A8A" />

            {/* Stage */}
            <rect x="15" y="40" width="30" height="5" fill="#1E3A8A" />

            {/* Base */}
            <path d="M10 45 L50 45 L55 65 L5 65 Z" fill="#1E3A8A" />

            {/* Lens Detail */}
            <circle cx="30" cy="35" r="6" fill="#2563EB" />
            <circle cx="30" cy="35" r="3" fill="#FFFFFF" />
        </g>

        {/* DNA Helix */}
        <path d="M85 30 Q 95 45, 85 60 Q 75 75, 85 90" fill="none" stroke="#2563EB" stroke-width="3" />
        <path d="M85 30 Q 75 45, 85 60 Q 95 75, 85 90" fill="none" stroke="#2563EB" stroke-width="3" />

        {/* Connecting Lines */}
        <line x1="82" y1="45" x2="88" y2="45" stroke="#2563EB" stroke-width="2" />
        <line x1="82" y1="60" x2="88" y2="60" stroke="#2563EB" stroke-width="2" />
        <line x1="82" y1="75" x2="88" y2="75" stroke="#2563EB" stroke-width="2" />

        {/* Medical Cross */}
        <g transform="translate(15, 35)">
            <rect x="0" y="5" width="15" height="5" fill="#2563EB" />
            <rect x="5" y="0" width="5" height="15" fill="#2563EB" />
        </g>
    </svg>
);

function Header() {
    return (
        <>
            {/* Certificate Banner */}
            <div className="certificate-banner">
                <div className="container">
                    <img
                        src={certImage}  // Use the imported image
                        alt="Accurate Imaging & Path Labs Certificate"
                        className="cert-image"
                    />
                </div>
            </div>

            {/* Your existing header code */}
            <header className="header">
                <div className="top-bar">
                    <div className="container">
                        <div className="contact-links">
                            <a href="tel:011-79660783" className="contact-item">
                                <svg className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                </svg>
                                011-79660783
                            </a>
                            <a href="tel:9899908545" className="contact-item">
                                <svg className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                </svg>
                                9899908545
                            </a>
                            <div className="address-item">
                                <svg className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                IA-16A, Phase 1, Ashok Vihar-52
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-header">
                    <div className="container">
                        <div className="brand">
                            <Logo />
                            <div className="brand-info">
                                <h1>ACCURATE IMAGING & PATH LABS</h1>
                                <p>Where Accuracy Meets Care Since 1982</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;