import React from 'react';
import '../styles/Doctors.css';

function Doctors() {
    const doctors = [
        {
            name: "Dr. RAMESH K. GARG",
            qualification: "M.B.B.S., M.D."
        },
        {
            name: "Dr. KIRTI GARG",
            qualification: "M.B.B.S., M.D."
        },
        {
            name: "DR AMIT GARG",
            qualification: "M.B.B.S., M.D."
        }
    ];

    return (
        <section className="doctors">
            <div className="container">
                <h2>Our Medical Experts</h2>
                <div className="doctors-grid">
                    {doctors.map((doctor, index) => (
                        <div key={index} className="doctor-card">
                            <h3>{doctor.name}</h3>
                            <p>{doctor.qualification}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Doctors;